import { useAuth0 } from '@auth0/auth0-react'
import { Suspender, NotFoundBanner } from '@mm/company-ui'
import React from 'react'
import { Frame } from '../modules/navigation'
import { Head } from '../modules/navigation/components/Head'

const Error404 = () => {
  const { user, isLoading } = useAuth0()

  if (isLoading) {
    return <Suspender />
  }
  if (user) {
    return (
      <Frame title="Not Found">
        <NotFoundBanner />
      </Frame>
    )
  } else {
    return (
      <>
        <Head title="Not Found" />
        <NotFoundBanner description="The page you're looking for no longer exists." />
      </>
    )
  }
}

export default Error404
